.boardContainer {
  height: 90%;
  width: 100%;
  flex: 1 1 auto;
  /* background: gray; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  flex-flow: center;
  align-items: center;
}

.leftPanel {
  flex: 1 1 auto;
  height: 100%;
  max-width: 600px;
  padding: 2rem;
  margin: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.rightPanel {
  flex: 1 1 auto;
  height: 100%;
  max-width: 1000px;
  padding: 2rem;
  margin: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.description {
  flex: 0 1 auto;
  text-align: start;
}
.description p {
  margin-top: 1rem;
}

.board {
  /* margin: 20px; */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-flow: start;
  align-items: stretch;
  background-color: rgb(208, 245, 255);
  /* outline: 2px solid rgb(134, 154, 189); */
  width: 500px;
  height: 500px;
}

.row {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: start;
  align-items: stretch;
  /* height: 50px; */
}

.previewCell {
  flex: 1 1 auto;
  /* width: 7px; */
}

.cell {
  flex: 1 1 auto;
  /* width: 50px; */

  /* outline: 1px solid rgb(134, 154, 189); */
}
.cell:hover {
  box-shadow: 0 0 5px 2px rgba(253, 82, 2, 0.61), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(253, 82, 2, 0.61);
}

.liveCell {
  background-color: rgb(8, 88, 17);
}

.controlPanel{
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.instructions{
  flex: 0 1 auto;
  text-align: start;
  margin: 1rem 1rem;
}

.controlBtns{
  flex: 0 0 auto;
}

.overlay{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
}

.libModel {
  background-color: white;
  width: 80%;
  height: 700px;
  border-radius: 5px;
  flex: 0 0 auto;
  padding: 2rem 4rem;
  /* margin-top: 1000px */
}

.previewCard {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgb(208, 245, 255);
  margin: 1rem;
  padding: 0px;
  /* border: 1px solid rgb(61, 61, 61); */
  border-radius: 4px;
}
.previewCard:hover {
  /* border: 1px solid rgb(50, 155, 2); */
  box-shadow: 0 0 10px 5px rgb(25, 75, 2, 0.3);
  /* box-sizing: border-box; */
}
.previewBoard {
  /* flex: 1 1 auto; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-flow: start;
  align-items: stretch;
  background-color: rgb(208, 245, 255);
  box-sizing: border-box;
  /* border: 0px; */
  outline: 2px solid rgb(26, 80, 1);
  border-radius: 4px;
}